import { useTheme } from "lib/contexts/themeContext";
import Button from "lib/components/Button";

interface ActionCloudProps {
  text: string;
  buttonText: string;
  color?: string;
  buttonColor?: string;
  onClick: (e: any) => void;
}

const ActionCloud: React.FC<ActionCloudProps> = ({
  text,
  buttonText,
  color,
  buttonColor,
  onClick,
}) => {
  const { breakpoints } = useTheme();

  return (
    <div
      css={`
        margin: 0 auto;
        position: relative;
        max-width: 940px;
        overflow: hidden;
        ${breakpoints.down("sm")} {
          max-width: 1227px;
        }
      `}
    >
      <div
        css={`
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 940px;
          ${breakpoints.down("sm")} {
            width: 1227px;
          }
        `}
      >
        <Cloud color={color} />
      </div>
      <div
        css={`
          padding: 0 20px;
          height: 310px;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          ${breakpoints.down("sm")} {
            height: 398px;
          }
        `}
      >
        <div
          css={`
            margin-bottom: 16px;
            line-height: 28px;
            font-size: 20px;
            font-weight: 700;
            text-align: center;
          `}
        >
          {text}
        </div>
        <div
          css={`
            margin-bottom: 10px;
            width: 100%;
            display: flex;
            justify-content: center;
            ${breakpoints.down("sm")} {
              flex-direction: column;
              width: 100%;
            }
          `}
        >
          <div
            css={`
              margin: 0 0 10px 0;
              width: 100%;
              max-width: 280px;
              ${breakpoints.down("sm")} {
                margin: 0 auto 10px;
                width: 100%;
                max-width: 322px;
              }
            `}
          >
            <Button
              css={`
                &&& {
                  height: 60px;
                  line-height: 26px;
                  font-size: 18px;
                  font-weight: 500;
                  background-color: ${buttonColor};
                  text-align: center;
                }
              `}
              fullWidth
              onClick={onClick}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionCloud;

interface CloudProps {
  color?: string;
}

const Cloud: React.FC<CloudProps> = ({ color }) => {
  const WIDTH_L = 1227;
  const WIDTH_S = 940;
  const { breakpoints } = useTheme();

  return (
    <div
      css={`
        position: relative;
        width: ${WIDTH_S}px;
        ${breakpoints.down("sm")} {
          width: ${WIDTH_L}px;
        }
      `}
    >
      <CloudCircle
        css={`
          left: 0;
        `}
        color={color}
      />
      <CloudCircle
        css={`
          right: ${WIDTH_S / 2}px;
          ${breakpoints.down("sm")} {
            right: ${WIDTH_L / 2}px;
          }
        `}
        color={color}
      />
      <CloudCircle
        css={`
          left: ${WIDTH_S / 2 - 300 / 2}px;
          ${breakpoints.down("sm")} {
            left: ${WIDTH_L / 2 - 398 / 2}px;
          }
        `}
        color={color}
      />
      <CloudCircle
        css={`
          left: ${WIDTH_S / 2}px;
          ${breakpoints.down("sm")} {
            left: ${WIDTH_L / 2}px;
          }
        `}
        color={color}
      />
      <CloudCircle
        css={`
          right: 0;
        `}
        color={color}
      />
    </div>
  );
};

interface CloudCircleProps {
  color?: string;
  className?: string;
}

const CloudCircle: React.FC<CloudCircleProps> = ({
  color = "#e8f0ff",
  className,
}) => {
  const { breakpoints } = useTheme();

  return (
    <div
      css={`
        position: absolute;
        top: 0;
        border-radius: 50%;
        width: 300px;
        height: 304px;
        background-color: ${color};
        ${breakpoints.down("sm")} {
          width: 398px;
          height: 398px;
        }
      `}
      className={className}
    />
  );
};

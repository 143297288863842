import { useTheme } from "lib/contexts/themeContext";

interface StatisticProps {
  title: string;
  value: React.ReactNode;
  suffix?: React.ReactNode;
  className?: string;
}

const Statistic: React.FC<StatisticProps> = ({
  title,
  value,
  suffix,
  className,
}) => {
  const { breakpoints } = useTheme();

  return (
    <div
      css={`
        text-align: center;
      `}
      className={className}
    >
      <div
        css={`
          margin-bottom: 20px;
          line-height: 34px;
          font-size: 24px;
          font-weight: 500;
          ${breakpoints.down("sm")} {
            line-height: 26px;
            font-size: 18px;
          }
        `}
      >
        {title}
      </div>
      <div
        css={`
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 56px;
          font-size: 40px;
          font-weight: 700;
          color: #325aff;
        `}
      >
        {value}
        {suffix}
      </div>
    </div>
  );
};

export default Statistic;

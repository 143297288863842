import { useCallback } from "react";
import Link from "next/link";
import NextImage from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper.min.css";
import { useTheme } from "lib/contexts/themeContext";
import IconBadgeAttorney from "lib/assets/badge-attorney.svg";
import IconChevronRight from "lib/assets/arrow-chevron.svg";
import ImgAttorneySahin from "lib/assets/attorney-sahin.png";
import ImgAttorneyKatona from "lib/assets/attorney-katona.png";
import ImgAttorneyMamari from "lib/assets/attorney-mamari.png";
import ImgAttorneyRodriguez from "lib/assets/attorney-rodriguez.png";
import ImgAttorneyGandhi from "lib/assets/attorney-gandhi.jpg";
import ImgAttorneyAsgard from "lib/assets/attorney-asgard.jpg";

SwiperCore.use([Autoplay]);

const AttorneyTestimonials = () => {
  const handleOnClick = useCallback((swiper, e) => {
    if (e.target instanceof HTMLElement) {
      if (e.target.tagName === "A") {
        return;
      }
    }
    if (swiper.clickedIndex > swiper.activeIndex) {
      swiper.slideNext();
    } else if (swiper.clickedIndex < swiper.activeIndex) {
      swiper.slidePrev();
    }
  }, []);

  return (
    <div>
      <Swiper
        css={`
          .swiper-slide {
            width: auto;
          }
        `}
        grabCursor
        autoplay={{ delay: 5000 }}
        slidesPerView="auto"
        spaceBetween={0}
        onClick={handleOnClick}
        loop
      >
        <SwiperSlide key="1">
          <div
            css={`
              margin-left: 20px;
              max-width: 829px;
              width: 80vw;
            `}
          >
            <AttorneyTestimonial
              name="Seyit Sahin"
              slug="seyit-a-sahin"
              color="#005a80"
              bgColor="#e6f8ff"
              badgeFill=""
              badgeStroke=""
              image={ImgAttorneySahin}
            >
              “Lawfully is by far one of the best immigration applications in
              the US.{" "}
              <span
                css={`
                  font-weight: 700;
                `}
              >
                I highly recommend Lawfully with no hesitation to all immigrants
                in order to track their USCIS cases
              </span>{" "}
              and have an idea about the processing times. Thanks Lawfully for
              your exemplary immigration services!”
            </AttorneyTestimonial>
          </div>
        </SwiperSlide>
        <SwiperSlide key="2">
          <div
            css={`
              margin-left: 20px;
              max-width: 829px;
              width: 80vw;
            `}
          >
            <AttorneyTestimonial
              name="David Katona"
              slug="david-katona"
              color="#932d00"
              bgColor="#fff2ec"
              badgeFill="#66d2ff"
              badgeStroke="#00b4ff"
              image={ImgAttorneyKatona}
            >
              “
              <span
                css={`
                  font-weight: 700;
                `}
              >
                Lawfully’s analytics are a powerful tool that help us better
                predict processing times
              </span>{" "}
              for our clients and gives clients strong insight into their cases.
              We view Lawfully as an indispensable part of our practice and are
              excited for the new tools and features to come!”
            </AttorneyTestimonial>
          </div>
        </SwiperSlide>
        <SwiperSlide key="3">
          <div
            css={`
              margin-left: 20px;
              max-width: 829px;
              width: 80vw;
            `}
          >
            <AttorneyTestimonial
              name="Joseph Mamari"
              slug="joseph-mamari"
              color="#001c91"
              bgColor="#f2e9ff"
              badgeFill="#ffae8a"
              badgeStroke="#ff9363"
              image={ImgAttorneyMamari}
            >
              “
              <span
                css={`
                  font-weight: 700;
                `}
              >
                Lawfully is a brilliant and excellent platform for both,
                attorneys and potential clients.
              </span>{" "}
              As an attorney, I use it to connect with potential clients. As a
              consumer, I use it to track all of my cases and obtain approximate
              timelines for petitions filed.”
            </AttorneyTestimonial>
          </div>
        </SwiperSlide>
        <SwiperSlide key="4">
          <div
            css={`
              margin-left: 20px;
              margin-right: 20px;
              max-width: 829px;
              width: 80vw;
            `}
          >
            <AttorneyTestimonial
              name="Adam Rodriguez"
              slug="adam-luis-rodriguez"
              color="#001c91"
              bgColor="#ebefff"
              badgeFill="#a266ff"
              badgeStroke="#8333ff"
              image={ImgAttorneyRodriguez}
            >
              “
              <span
                css={`
                  font-weight: 700;
                `}
              >
                Lawfully is reshaping the immigration law industry by providing
                its users access to affordable and licensed U.S. immigration
                attorneys.
              </span>{" "}
              It’s no secret that immigration laws in the United States are
              complex and not always uniform. But Lawfully empowers legal
              migration by providing its users access to an affordable and
              licensed immigration attorney regardless of where the user
              resides. The best part of it all? Lawfully can be used from a
              mobile device.”
            </AttorneyTestimonial>
          </div>
        </SwiperSlide>
        <SwiperSlide key="5">
          <div
            css={`
              margin-left: 20px;
              margin-right: 20px;
              max-width: 829px;
              width: 80vw;
            `}
          >
            <AttorneyTestimonial
              name="H. K. Gandhi"
              slug="h-k-gandhi"
              color="#005A80"
              bgColor="#E6F8FF"
              badgeFill="#a266ff"
              badgeStroke="#8333ff"
              image={ImgAttorneyGandhi}
            >
              Lawfully brings transparency to the world of immigration
              processing times. Their analytics help provide insight and clarity
              on where your case is throughout the immigration process. Lawfully
              goes one step further by providing you with access to high quality
              attorneys, when you need a custom tailored response that goes
              beyond metrics.{" "}
              <span
                css={`
                  font-weight: 700;
                `}
              >
                Lawfully is the best app-based solution for immigrants and
                immigration to the United States.
              </span>{" "}
            </AttorneyTestimonial>
          </div>
        </SwiperSlide>
        <SwiperSlide key="6">
          <div
            css={`
              margin-left: 20px;
              margin-right: 20px;
              max-width: 829px;
              width: 80vw;
            `}
          >
            <AttorneyTestimonial
              name="Ramin Asgard"
              slug="ramin-asgard"
              color="#932D00"
              bgColor="#FFF2EC"
              badgeFill="#66D2FF"
              badgeStroke="#00B4FF"
              image={ImgAttorneyAsgard}
            >
              <span
                css={`
                  font-weight: 700;
                `}
              >
                Lawfully has done an amazing job providing high quality U.S.
                immigration information, and for the first time made it
                accessible, affordable, and convenient for everyone.
              </span>{" "}
              I am very proud to have been with Lawfully since their launch and
              can think of no other service that has helped so many immigrants
              gain access to experts easily or so many immigration attorneys
              affordably reach a diverse client base. Congratulations to
              Lawfully for all they have done, and all their exciting future
              product and service breakthroughs to come.
            </AttorneyTestimonial>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default AttorneyTestimonials;

interface AttorneyTestimonialProps {
  name: string;
  slug: string;
  bgColor: string;
  color: string;
  badgeFill: string;
  badgeStroke: string;
  image: string;
  reverse?: boolean;
}

const AttorneyTestimonial: React.FC<AttorneyTestimonialProps> = ({
  name,
  slug,
  bgColor,
  color,
  badgeFill,
  badgeStroke,
  image,
  reverse,
  children,
}) => {
  const { breakpoints } = useTheme();

  return (
    <div
      css={`
        border-radius: 20px;
        padding: 0 80px;
        max-width: 829px;
        height: 318px;
        display: flex;
        align-items: center;
        flex-direction: ${reverse ? "row-reverse" : "row"};
        background-color: ${bgColor};
        ${breakpoints.down("sm")} {
          flex-direction: column;
          padding: 30px;
          height: auto;
        }
      `}
    >
      <div
        css={`
          flex: 1;
          border-left: ${reverse ? "1px solid rgba(0, 0, 0, 0.1)" : "none"};
          border-right: ${reverse ? "none" : "1px solid rgba(0, 0, 0, 0.1)"};
          padding-left: ${reverse ? 60 : 0}px;
          padding-right: ${reverse ? 0 : 60}px;
          line-height: 24px;
          font-size: 16px;
          ${breakpoints.down("sm")} {
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            margin-bottom: 20px;
            padding: 0;
            padding-bottom: 20px;
            line-height: 22px;
            font-size: 14px;
          }
        `}
      >
        {children}
      </div>
      <div
        css={`
          padding-left: ${reverse ? 0 : 60}px;
          padding-right: ${reverse ? 60 : 0}px;
          text-align: center;
          ${breakpoints.down("sm")} {
            padding: 0;
          }
        `}
      >
        <div
          css={`
            position: relative;
            margin-bottom: 10px;
            width: 140px;
            height: 140px;
            ${breakpoints.down("sm")} {
              padding: 0;
              width: 100px;
              height: 100px;
              margin: 0 auto 10px;
          `}
        >
          <div
            css={`
              overflow: hidden;
              width: 100%;
              border-radius: 70px;
            `}
          >
            <NextImage
              unoptimized={!!process.env.NEXT_PUBLIC_NETLIFY}
              src={image}
              width={2.5 * 140}
              height={2.5 * 140}
            />
          </div>
          <IconBadgeAttorney
            css={`
              position: absolute;
              bottom: 0;
              ${reverse ? "left: 0;" : "right: 0;"}
              circle {
                stroke: ${badgeStroke};
              }
              path {
                fill: ${badgeFill};
              }
              ${breakpoints.down("sm")} {
                transform: scale(0.8182);
                bottom: -6px;
                ${reverse ? "left: -6px;" : "right: -6px;"}
              }
            `}
          />
        </div>
        <div
          css={`
            line-height: 26px;
            font-size: 18px;
            font-weight: 700;
            color: ${color};
          `}
        >
          {name}
        </div>
        <div
          css={`
            margin-bottom: 6px;
            line-height: 22px;
            font-size: 14px;
            font-weight: 500;
            color: ${color};
          `}
        >
          Immigration Attorney
        </div>
        <Link href={`https://www.lawfully.com/lawyers/${slug}`} passHref>
          <a
            css={`
              line-height: 22px;
              font-size: 14px;
              font-weight: 500;
              color: ${color};
              opacity: 0.5;
              display: flex;
              align-items: center;
              justify-content: center;
              :hover {
                color: ${color};
              }
            `}
          >
            See more
            <IconChevronRight
              css={`
                transform: scale(0.7777);
                path {
                  stroke: ${color};
                }
              `}
            />
          </a>
        </Link>
      </div>
    </div>
  );
};
